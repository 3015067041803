<template>
    <div class="box">
        <!-- 头部 -->
        <headers></headers>
        <el-carousel :interval="3000">
            <el-carousel-item>
                 <img src="@/assets/homePage/ja-关于盛泰banner.png" class="images">
            </el-carousel-item>
        </el-carousel>
        <!-- 中间内容区域 -->
        <div class="content">
            <h2 class="H2">技術開発</h2>
            <img class="img_1" src="@/assets/about/research/ja-技术研发.png" alt="">
            <div class="box_1"></div>
            <div class="text" v-for="item in dataList" :key="item.id">
                <h2>{{ item.name }}</h2>
                <p>{{ item.title }}</p>
            </div>
            <div class="imgs">
                <img class="imgs_1" src="@/assets/about/research/项目配图1.png" alt="">
                <img class="imgs_2" src="@/assets/about/research/项目配图2.png" alt="">
            </div>
            <div class="box_2"></div>
            <div class="textImg">
                <img class="img_1" src="@/assets/about/research/项目配图3.png" alt="">
                <div class="div_bottom">
                    <div v-for="item in titleList" :key="item.id">
                        <li style="font-size: 1.5rem;">{{ item.title_top }}</li>
                        <p style="text-indent: -0.3em;">{{ item.title_bottom }}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 底部 -->
        <foot></foot>
    </div>
    <div class="_div">
        <!-- 头部 -->
        <move_header></move_header>
        <!-- 轮播图 -->
        <van-swipe :autoplay="3000" indicator-color="white">
            <van-swipe-item>
                 <img src="@/assets/homePage/ja-关于盛泰banner.png" class="images">
            </van-swipe-item>
        </van-swipe>
        <!-- 内容区域 -->
        <div class="content">
            <h2 class="H2">技術開発</h2>
            <img class="img_1" src="@/assets/about/research/ja-技术研发.png" alt="">
            <div class="box_1"></div>
            <div class="text" v-for="item in dataList" :key="item.id">
                <h3>{{ item.name }}</h3>
                <p>{{ item.title }}</p>
            </div>
            <div class="imgs">
                <img class="imgs_1" src="@/assets/about/research/项目配图1.png" alt="">
                <img class="imgs_2" src="@/assets/about/research/项目配图2.png" alt="">
            </div>
            <div class="box_2"></div>
            <div class="textImg">
                <!-- <img class="img_1" src="@/assets/about/research/项目配图3.png" alt=""> -->
                <div class="div_bottom">
                    <div v-for="item in titleList" :key="item.id">
                        <li>{{ item.title_top }}</li>
                        <p >{{ item.title_bottom }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <move_foot></move_foot>
    </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRoute } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
    components: { foot, headers, move_header, move_foot },

    setup() {
        const route = useRoute();
        const state = reactive({
            dataList: [
                { id: 1, name: '48M+5Mデュアルカメラモジュール', title: '4800万画素のフォーカスカメラと500万画素の固定焦点カメラは、金属のブラケットで組み合わせて1つのカメラを構成します。' },
                { id: 2, name: '64 M FNO 1.8大口径モジュール', title: "6400万画素大絞りは、レンズの大絞りを大きくすることで光量を増やし、夜景を明るくして暗い環境での撮影を可能にします。" },
                { id: 3, name: '64M OIS光学式手ブレ補正モジュール', title: "光学式手ブレ補正の原理を応用した6400万画素の光学カメラにより、動画結果の安定性が向上します。" },
                { id: 4, name: '1億画素大FNO1.7大口径モジュール', title: "1億画素は、強力な光感度、豊かな画像ディテール、写真撮影時間の短縮を実現します。" },
                { id: 5, name: '2億画素大口径FNO1.97大口径モジュール', title: "2億画素の超高画素は、強力な光感度、豊かな画像ディテール、写真撮影時間の短縮を実現しています。" },
            ],
            titleList: [
                { id: 1, title_top: '二次材料評価能力', title_bottom: '材料評価能力、リスク識別能力、設計最適化能力、新材料の予備研究能力、経験が沈殿した。' },
                { id: 2, title_top: 'ラボ評価能力', title_bottom: "flare評価分析;解析的評価分析;モジュールIQ評価分析。" },
                { id: 3, title_top: '新技術関連ソフトウェアとアルゴリズムを開発する能力', title_bottom: "プログラムモジュール化、デフォーカス曲線監視テストプログラム、OISデバッグソフトウェア開発、光漏れテストアルゴリズム、flareテストプログラム等。" },
                { id: 4, title_top: '設備を開発し、設備改造と革新能力', title_bottom: "オフフォーカルカーブ試験設備、OISテスト設備など" },
                { id: 5, title_top: '上下流の資源をつないで協同開発する能力', title_bottom: "SMAモーターの開発、G+Plensの検証、ドライバICのデバッグ開発で協力する。" },
                { id: 6, title_top: '新技術、新材料、新設計、新プロセス、新方法の予備研究能力', title_bottom: "48 M/50M/64M/OISは既に量産能力を備えており,Periscope/SMAYT/SMA AF/の開発を進めている。" },
                { id: 7, title_top: '故障解析能力', title_bottom: "故障分析工程を規範化し、エンジニアが正確かつ迅速に故障原因を探し出し、プロジェクトを再検討し、経験を蓄積する。" },
                { id: 8, title_top: '開発プロセスの整備、PLMシステム導入、IT化管理の推進', title_bottom: "" },
            ]
        });
        onMounted(() => {
        });
        return {
            ...toRefs(state),
        };
    },
};
</script>

<style scoped lang="less">
.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}

.box {

    width: 100%;

    .content {
        max-width: 1920px;
        margin: 0 auto;
        width: 70%;

        .textImg {
            box-sizing: border-box;
            display: flex;

            .img_1 {
                flex: 1;
                width: 100%;
                max-height: 800px;
                height: auto;
                padding: 30px 30px 30px 0;
                box-sizing: border-box;
            }

            .div_bottom {
                flex: 3;
                padding: 30px 0;
                li {
                    font-size: 25px;
                    font-weight: bold;
                    margin: 10px 0;
                }

                p {
                    font-size: 20px;
                    margin: 0;
                    margin-left: 40px;
                    margin-bottom: 30px;
                }
            }
        }

        .imgs {
            width: 100%;
            height: 500px;
            box-sizing: border-box;

            .imgs_1 {
                height: 100%;
                width: 30%;
            }

            .imgs_2 {
                height: 100%;
                padding-left: 60px;
                width: 65%;
            }
        }

        .text {
            position: relative;
            width: 100%;
            height: 100px;
            margin: 20px 0;
            padding-left: 100px;
            h2{
                margin: 0;
            }
            p{  
                margin: 10px 0;
                font-size: 20px;
            }
        }

        .text::after {
            content: '';
            position: absolute;
            left: 30px;
            top: 12px;
            width: 40px;
            height: 40px;
            background-color: #302F30;
        }

        .box_1,
        .box_2 {
            position: relative;
            width: 100%;
            height: 40px;
            color: #fff;
            background-color: #EEEEEE;
            margin: 50px 0;
        }

        .box_1::after {
            position: absolute;
            left: 0;
            top: 0;
            content: 'プロジェクト概要';
            width: 260px;
            line-height: 65px;
            font-size: 25px;
            height: 65px;
            background-color: #2D71D7;
            font-weight: bold;
            text-align: center;
        }

        .box_2::after {
            position: absolute;
            left: 0;
            top: 0;
            content: '研究開発能力';
            width: 260px;
            line-height: 65px;
            font-size: 25px;
            height: 65px;
            background-color: #2D71D7;
            font-weight: bold;
            text-align: center;
        }

        .img_1 {
            width: 100%;
        }

        .H2 {
            margin-top: 50px;
            font-size: 1.7rem;
        }

        .H2::before {
            content: "";
            width: 30%;
            height: 2px;
            background-color: #c7000b;
            position: absolute;
            margin-top: 40px;
            left: 18%;
            transform: translateX(-50%);
            // top:55px;
            min-width: 35px;
            max-width: 35px;
        }
    }
}

.images {
    width: 100%;
    height: auto;
    max-height: 600px;
}

::v-deep .el-carousel {
    max-width: 1920px;
    margin: 0 auto;
    overflow-y: hidden;
    height: auto;
    max-height: 600px;
    // max-height: 700px;
}

::v-deep .el-carousel__container {
    height: 100% !important;
    min-height: 500px;
}

.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}

::v-deep .el-card {
    border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
    .box {
        display: block;
    }

    ._div {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .box {
        display: none;
    }

    ._div {
        display: block;

        .content {
       
            width: 100%;
            padding: 30px;
            box-sizing: border-box;
            .textImg {
                box-sizing: border-box;
                display: flex;

                .img_1 {
                    flex: 1;
                    width: 100%;
                    max-width: 700px;
                    max-height: 100%;
                    padding: 30px 30px 30px 0;
                    box-sizing: border-box;
                }

                .div_bottom {
                    flex: 3;
                    padding: 30px 0;

                    li {
                        font-size: 50px;
                        font-weight: bold;
                    }

                    p {
                        font-size: 45px;
                         margin-left: 70px;
                    }
                }
            }

            .imgs {
                // width: 98%;
                height: 800px;
                box-sizing: border-box;
                margin-top: 100px;
                .imgs_1 {
                    height: 100%;
                    width: 33%;
                }

                .imgs_2 {
                    height: 100%;
                    padding-left: 30px;
                    width: 65%;
                    
                }
            }

            .text {
                position: relative;
                
                width: 80%;
                padding-left: 300px;
                height: auto;
                margin: 100px 0;
                h3{
                    font-size: 70px;
                    margin: 20px 0;
                }
                p{
                    margin: 0;padding: 0;
                    font-size: 50px;
                }
            }

            .text::after {
                content: '';
                position: absolute;
                left: 110px;
                top: 80px;
                width: 100px;
                height: 100px;
                background-color: #302F30;
            }

            .box_1,
            .box_2 {
                position: relative;
                width: 100%;
                height: 170px;
                background-color: #EEEEEE;
                margin: 50px 0;
                margin-bottom: 150px;
            }
     
            .box_1::after {
                position: absolute;
                left: 0;
                top: 0;
                content: 'プロジェクト概要';
                width: 800px;
                line-height: 240px;
                font-size: 80px;
                height: 240px;
                background-color: #2D71D7;
                font-weight: bold;
                color: #fff;
                text-align: center;
            }

            .box_2::after {
           position: absolute;
                left: 0;
                top: 0;
                content: '研究開発能力';
                width: 800px;
                line-height: 240px;
                font-size: 80px;
                color: #fff;
                height: 240px;
                background-color: #2D71D7;
                font-weight: bold;
                text-align: center;
            }

            .img_1 {
                width: 100%;
            }
                  h2 {
        text-align: center;
        font-size: 80px;
      }
      h2::before {
        content: "";
        width: 30%;
        height: 8px;
        background-color: #c7000b;
        position: absolute;
        margin-top: 100px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100px;
        max-width: 100px;
      }
        }
    }
}
</style>